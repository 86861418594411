import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { useRef } from 'react';
import ExportDropdown from './ExportDropdown';
import './plugins';
import { CHART_COLORS } from '@/consts';
import { isDefined } from '@/utils/miscUtils';

const buildOptions = (yAxisLabel, results) => ({
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: results?.datasets?.length > 1,
      position: 'bottom',
      align: 'middle',
    },
    yAxisLabel: {
      enabled: true,
      text: yAxisLabel,
    },
  },
  // indexAxis: 'y',
  responsive: true,
  animation: true,
  layout: {
    padding: {
      right: 50,
      top: 50,
      left: 30,
    },
  },
  scales: {
    x: {
      stacked: true,

      ticks: {
        autoSkip: false,
      },

      border: {
        color: '#e7e5e4',
      },

      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        display: true,
        lineWidth: 2,
      },
    },
    y: {
      title: { display: false },
      stacked: true,
      beginAtZero: true,
      border: { dash: [4, 4], display: false },
    },
  },
});

const buildData = (data, colors) => {
  const { columns, rows } = data;
  const datasets = rows.map((row, i) => ({
    label: row[0],
    data: row.slice(1),
    backgroundColor: colors[i],
    borderWidth: 3,
    barThickness: 70,
    borderColor: ['rgb(255, 255, 255)'],
  }));

  return {
    labels: columns,
    datasets,
  };
};

const drawTotalsPlugin = {
  id: 'drawTotals',
  afterDatasetsDraw(chart) {
    const { ctx } = chart;
    const bars = {};
    const { datasets } = chart.data;

    ctx.save();

    datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);

      if (!meta.visible) return;

      meta.data.forEach((bar, index) => {
        const total = datasets.reduce((sum, ds, idx) => {
          const dsMeta = chart.getDatasetMeta(idx);
          return dsMeta.visible ? sum + ds.data[index] : sum;
        }, 0);

        const { x, y } = bar;
        const totalStr = total.toFixed(3);

        if (!isDefined(bars[x])) {
          bars[x] = { y: Number.MAX_VALUE };
        }

        if (bars[x].y > bar.y) {
          bars[x] = { totalStr, y, bar };
        }
      });
    });

    for (let x in bars) {
      const { bar, totalStr } = bars[x];

      ctx.fillStyle = '#666666';
      ctx.font = '11px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillText(totalStr, bar.x, bar.y - 5);
    }
  },
};

const StackedBarChart = ({ rows, columns, yAxisLabel, colors = CHART_COLORS, labelsInCSV = false }) => {
  const data = buildData({ rows, columns }, colors);
  const options = buildOptions(yAxisLabel, data);
  const ref = useRef();

  return (
    <div className="relative w-full">
      <ExportDropdown forwardedRef={ref} data={{ ...data, ...(labelsInCSV && { labelsColumn: 'Name' }) }} />
      <Bar ref={ref} options={options} data={data} plugins={[drawTotalsPlugin]} />
    </div>
  );
};

StackedBarChart.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array),
  columns: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  yAxisLabel: PropTypes.string,
  labelsInCSV: PropTypes.bool,
};

export default StackedBarChart;
