import { registryActions, useActiveNodeTypes, useCategories, useActiveCategory } from '@/stores/registryStore';
import RegistryForm from './RegistryForm';
import { ArrowsPointingInIcon } from '@heroicons/react/24/outline';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import Walkthrough from '../core/Walkthrough';

const defaultValues = {};
const CategoriesList = () => {
  const categories = useCategories();
  const activeCategory = useActiveCategory();

  return (
    <ul className="ml-2 mr-1 pr-2 p-x-4 mt-2 overflow-x-auto scrollbar-thin">
      {categories.map(category => (
        <li key={category} className={`hover:bg-gray-50 py-1 cursor-pointer`}>
          <button
            onClick={() => registryActions.setActiveCategory(category)}
            className="text-xs inline-flex items-center pl-2 pr-2 px-1 text-left w-full truncate"
          >
            <div
              className={`mr-2 w-2 h-2 rounded border shrink-0 ${activeCategory === category ? 'font-bold rounded bg-orange-800 border-orange-800' : 'border-orange-800'}`}
            />
            {category}
          </button>
        </li>
      ))}
    </ul>
  );
};

const PathwayRegistry = () => {
  const activeNodeTypes = useActiveNodeTypes();
  const [collapsed, setCollapsed] = useState(false);
  const toolbar = useRef(null);
  const collapsedBlock = useRef(null);
  const { width, height } = collapsedBlock?.current?.getBoundingClientRect() || { width: 195, height: 48 };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('nodeType', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onSubmit = useCallback(data => {
    registryActions.updateSearch(data);
  }, []);

  useEffect(() => {
    if (collapsed) {
      toolbar.current.updateSize({ width: `${width}px`, height: `${height}px` });
    } else {
      toolbar.current.updateSize({ width: '65%', height: '320px' });
    }
  }, [collapsed, width, height]);

  useEffect(
    () => () => {
      registryActions.clear();
    },
    [],
  );

  return (
    <Rnd
      bounds="parent"
      minWidth={width}
      minHeight={height}
      enableUserSelectHack={false}
      className="z-[1] m-2 !translate-x-0"
      ref={c => (toolbar.current = c)}
      disableDragging
    >
      <div
        className={`group rounded overflow-hidden select-none bg-base-100 h-full border border-gray-200 hover:border-blue-300 ${collapsed ? 'collapsed' : ''}`}
      >
        <div className="flex h-full group-[.collapsed]">
          <div className="min-w-max flex flex-col module-categories">
            <div className="flex items-center module-palette-control" ref={collapsedBlock}>
              <div className="text-lg font-semibold mr-4 pt-2 pl-4 pb-2">Modules</div>
              <button onClick={() => setCollapsed(false)} className="hidden group-[.collapsed]:block">
                <ArrowsPointingOutIcon className="h-8 m-2 ml-8 border border-gray-200 rounded p-1 text-gray-400 hover:text-gray-300" />
              </button>

              <button onClick={() => setCollapsed(true)} className="block group-[.collapsed]:hidden">
                <ArrowsPointingInIcon className="h-8 m-2 ml-8 border border-gray-200 rounded p-1 text-gray-400 hover:text-gray-300" />
              </button>
            </div>
            <CategoriesList />
          </div>

          <div className="my-2 overflow-hidden flex flex-col module-selection">
            <RegistryForm defaultValues={defaultValues} onSubmit={onSubmit} />

            <div className="overflow-x-auto scrollbar-thin">
              {activeNodeTypes.map(({ name, label, disabled }) => (
                <button
                  draggable={!disabled}
                  key={name}
                  onMouseDown={event => {
                    event.stopPropagation();
                  }}
                  onDragStart={event => {
                    onDragStart(event, name);
                  }}
                  style={{ cursor: disabled ? 'default' : 'grab' }}
                  className={`text-xs ${disabled ? 'bg-neutral-50 text-gray-400' : 'bg-gray-100 hover:bg-blue-100'} rounded-sm p-2 m-1`}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Walkthrough type="modules" />
    </Rnd>
  );
};

export default PathwayRegistry;
