import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import ComparisonActionDropdown from './ComparisonActionDropdown';
import { useEffect } from 'react';
import { comparisonActions } from '@/stores/comparisonStore';
import ListHeader from '../core/ListHeader';
import ListItemLink from '../core/ListItemLink';

const ComparisonsList = ({ comparisons = [] }) => {
  const { projectId } = useParams();

  const navigate = useNavigate();

  const addNew = event => {
    event.stopPropagation();
    navigate(`/projects/${projectId}/comparisons/new`);
  };

  useEffect(() => () => comparisonActions.unmount(), []);

  return (
    <div className="divide-y divide-[#EAEAEA]">
      <ListHeader title="Comparison Cases" icon={ArrowsRightLeftIcon} onAdd={addNew} />
      {comparisons.length > 0 && (
        <ul className="p-2">
          {comparisons.map(comparison => (
            <li key={comparison.id} className="group/item">
              <ListItemLink to={`/projects/${projectId}/comparisons/${comparison.id}`}>
                <span className="truncate">{comparison.name}</span>
                <div className="group-hover/item:flex hidden">
                  <ComparisonActionDropdown comparison={comparison} />
                </div>
              </ListItemLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ComparisonsList.propTypes = {
  comparisons: PropTypes.arrayOf(PropTypes.object),
};

export default ComparisonsList;
