import config from '@/config';
import axios from 'axios';

const { get, patch, post } = axios;

export const getCase = caseId => get(`/v1/cases/${caseId}/`);
export const patchAnchor = (caseId, anchor) => patch(`/v1/cases/${caseId}/`, { anchor });
export const patchParams = (caseId, params, mc_params = null) =>
  patch(`/v1/cases/${caseId}/`, { params, ...(mc_params ? { mc_params } : {}) });
export const balanceCase = caseId => post(`/v1/cases/${caseId}/balance/`);
export const createCostOverride = (caseId, data) => post(`/v1/cases/${caseId}/cost_overrides/`, data);
export const patchCostOverride = (costId, data) => patch(`/v1/cost_overrides/${costId}/`, data);
export const deleteCostOverride = costId => axios.delete(`/v1/cost_overrides/${costId}/`);
export const analyzeCase = (caseId, params) => post(`/v1/cases/${caseId}/analyze/`, { ...(params ? params : {}) });
export const duplicateCase = (caseId, params) => post(`/v1/cases/${caseId}/duplicate/`, params ?? {});

export const exportCaseDataUrl = caseId => `${config.serverUrl}/v1/cases/${caseId}/export/`;
