import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import userApi from '@/api/user';

const initialValues = {
  walkthroughs: {
    comparison: true,
  },
  theme: 'sesame-small',
  appVersion: null,
  gettingStarted: {},
};

const useSettingsStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'settingsStore',
      version: 1,
    }),
  ),
);

const { setState: set } = useSettingsStore;

// actions
export const settingsActions = {
  disableWalkthrough: name =>
    set(state => {
      state.walkthroughs[name] = false;
    }),

  enableWalkthrough: name =>
    set(state => {
      state.walkthroughs[name] = true;
    }),

  updateTheme: theme => {
    set({ theme });
  },

  fetchVersion: async () => {
    const { data } = await userApi.getVersion();
    set({ appVersion: data.version });
  },

  setGettingStarted: (projectId, settings) =>
    set(state => {
      state.gettingStarted[projectId] = {
        ...state.gettingStarted[projectId],
        ...settings,
      };
    }),

  closeGettingStartedPrompt: projectId => {
    settingsActions.setGettingStarted(projectId, { isPromptClosed: true });
  },

  resetGettingStarted: projectId =>
    set(state => {
      state.gettingStarted[projectId] = {
        isPromptClosed: false,
        skippedSteps: [],
      };
    }),

  skipGettingStartedStep: (projectId, stepIndex) =>
    set(state => {
      const projectSettings = state.gettingStarted[projectId] || { skippedSteps: [] };
      if (!projectSettings.skippedSteps.includes(stepIndex)) {
        projectSettings.skippedSteps.push(stepIndex);
      }
      state.gettingStarted[projectId] = projectSettings;
    }),
};

// selectors
export const useTourSettings = () => useSettingsStore(store => store.tours);
export const useTheme = () => useSettingsStore(store => store.theme);
export const useAppVersion = () => useSettingsStore(store => store.appVersion);

export const useIsWalkthroughEnabled = type =>
  useSettingsStore(store => {
    return store?.walkthroughs?.[type] === undefined || store?.walkthroughs?.[type] === true;
  });
export const useGettingStartedSettings = projectId =>
  useSettingsStore(store => store.gettingStarted[projectId] || { isPromptClosed: false, skippedSteps: [] });

export default useSettingsStore;
