import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Await, useAsyncValue, useMatches } from 'react-router-dom';

const PageTitleContent = ({ match }) => {
  const data = useAsyncValue();
  const titleData = match.handle.title(data);

  return (
    <>
      <span>{titleData?.title}</span>
      {titleData?.actions?.map((ActionComponent, index) => (
        <ActionComponent key={`title-action-${index}`} />
      ))}
    </>
  );
};

PageTitleContent.propTypes = {
  match: PropTypes.object,
};

const PageTitle = ({ className }) => {
  const matches = useMatches();
  const titles = matches.filter(match => Boolean(match.handle?.title));

  if (titles.length === 0) return null;

  const lastTitle = titles[titles.length - 1];

  return (
    <div
      className={`flex justify-between items-center w-full p-4 text-xl font-semibold border-b border-[#D5D7DD] ${className}`}
    >
      <Suspense fallback={<span className="loading loading-dots loading-md"></span>}>
        <Await resolve={lastTitle.data?.promise ?? lastTitle.data}>
          <PageTitleContent match={lastTitle} />
        </Await>
      </Suspense>
    </div>
  );
};

PageTitle.propTypes = {
  className: PropTypes.string,
};

export default PageTitle;
