import ProjectList from '@/components/projects/ProjectList';
import { Link, Outlet } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import PageTitle from '@/components/core/PageTitle';

export const NewProjectLink = () => (
  <Link to="/projects/new" className="btn btn-primary btn-sm">
    <PlusIcon className="h-5 w-5 fill-gray-100" />
    New Project
  </Link>
);

const ProjectsRoute = () => (
  <div className="flex flex-col bg-white shadow-lg shadow-slate-200 rounded-t-lg h-full size-11/12 overflow-hidden">
    <PageTitle className="bg-[#475569] text-white" />
    <div className="overflow-y-scroll">
      <div className="flex flex-grow">
        <div className="w-[60%] p-4">
          <div className="flex justify-between font-bold text-lg ml-3 mb-2 border-b p-4">
            <div className="font-bold text-lg">Projects</div>
            <NewProjectLink />
          </div>
          <ProjectList />
        </div>
        <div className="w-[40%] p-4">
          <div className="rounded-md bg-gray-100 p-8">
            <div className="font-bold text-lg mb-2">What’s new in Sesame 0.2.0 🎉</div>
            <ul>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  1
                </div>
                <b>New Look!</b> - Sesame got a brand new look for this release, which simplifies navigation and
                provides more space.
              </li>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  2
                </div>
                <b>Locking Pathways</b> - Fearing a change in a pathway used by multiple colleagues? Lock the pathway to
                prevent any changes.
              </li>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  3
                </div>
                <b>Hydrogen System Improvements</b> - Initial feedback was implemented, with improved parameters and
                tooltips. All systems will need to be re-run due to these changes.
              </li>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  4
                </div>
                <b>Mass & Energy Checks</b> - If the mass and energy balances does not check out in a module, Sesame
                will provide a warning.
              </li>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  5
                </div>
                <b>Carbon Capture Improvements</b> - Plant sizing now depends on the upstream
                powerplant capacity factor. The levelized cost of captured carbon is also accessible in the Analyze section.
              </li>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  6
                </div>
                <b>Sensitivity Results Exports</b> - Results from the pre-configured sensitivity graphs and the sensitivity workbench can be downloaded.
              </li>
              <li className="text-sm mt-4">
                <div className="mb-4 rounded-full float-left w-7 h-7 text-center align-middle p-1 mr-2 bg-[#02AD74] text-white font-bold">
                  7
                </div>
                <b>New Modules</b> - The Integrated LOHC module, and a wide array of boilers for
                process heat.
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/release_notes/"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Check out the full release notes
                </Link>
              </li>
            </ul>
          </div>
          <div className="rounded-md bg-gray-100 p-8 mt-8">
            <div className="font-bold text-lg mb-2">Helpful How-Tos</div>
            <ul>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#creating-a-pathway"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Creating a Pathway
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#changing-pathway-parameters"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Changing Pathway Parameters
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#running-a-pathway"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Running a Pathway
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#visualizing-results"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Visualizing Results
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#comparison-of-multiple-pathways"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Comparison of Multiple Pathways
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  </div>
);

export default ProjectsRoute;
