import axios from 'axios';
import userStore, { userActions } from '@/stores/userStore';
import config from '@/config';
import { toast } from 'react-toastify';

const { serverUrl } = config;
let errorId;

const parseErrorMessage = error => {
  let message = 'Oops. Something went wrong - ';

  const contentType = error?.response?.headers?.['content-type'] ?? '';

  if (contentType.match('html') || !error?.response?.data) {
    message = error.message;
  } else {
    const { data } = error.response;

    if (data.detail) {
      message = data.detail;
    } else {
      const errors = data?.params ? data.params : data;

      Object.keys(errors).forEach(key => {
        if (key === 'non_field_errors') {
          // error does not pertain to a specific field
          message += data[key].join('\n');
        } else {
          // error pertains to a specific field
          message += `${key}: ${data[key]} \n`;
        }
      });
    }
  }

  return message;
};

axios.interceptors.request.use(reqConfig => {
  const { accessToken } = userStore.getState();

  if (accessToken) {
    reqConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (!reqConfig.url.startsWith('http')) {
    reqConfig.url = `${serverUrl}${reqConfig.url}`;
  }

  return reqConfig;
});

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    if (originalRequest?.url?.match(/refresh/)) {
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const result = await userActions.refresh();

      if (result) {
        return axios(originalRequest);
      } else {
        userActions.logout();
      }
    } else {
      // TODO: remove this after tea endpoint returns 200
      if (error?.request?.responseURL?.match(/tea|lca|coac|password|sweep/)) {
        return Promise.reject(error);
      }

      const message = parseErrorMessage(error);

      toast.clearWaitingQueue();

      if (!toast.isActive(errorId)) {
        errorId = toast.error(message);
      }
    }

    return Promise.reject(error);
  },
);
