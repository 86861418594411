import PropTypes from 'prop-types';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ANALYSIS_STATUS } from '@/consts';
import SystemActionsDropdown from './SystemActionsDropdown';
import SystemsIcon from '@/assets/react-icons/md/SystemsIcon.svg?react';
import { useSystems } from '@/stores/resourcesStore';
import ListHeader from '../core/ListHeader';

const SystemList = () => {
  const systems = useSystems();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const addNew = event => {
    event.stopPropagation();
    navigate(`/projects/${projectId}/build/systems/new`);
  };

  return (
    <div className="divide-y divide-[#EAEAEA]">
      <ListHeader onAdd={addNew} title="Systems" icon={SystemsIcon} />
      {systems.length > 0 && (
        <ul className="p-2">
          {systems.map(system => (
            <li key={system.id} className="group/item">
              <NavLink
                className={({ isActive }) =>
                  `flex items-center justify-between rounded-md py-2 pl-6 pr-2 text-sm leading-6 text-gray-900 ${isActive ? 'bg-gray-100' : 'hover:bg-gray-100'}`
                }
                to={`/projects/${projectId}/build/systems/${system.id}`}
              >
                <span className="truncate">{system.name}</span>
                {system.status === ANALYSIS_STATUS.succeeded && (
                  <div className="group-hover/item:hidden">
                    <CheckCircleIcon className="text-green-500 h-4 w-4" />
                  </div>
                )}
                <div className="group-hover/item:flex hidden">
                  <SystemActionsDropdown system={system} />
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SystemList.propTypes = {
  project: PropTypes.object,
};

export default SystemList;
