import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { MapIcon } from '@heroicons/react/24/outline';
import PathwaysIcon from '@/assets/react-icons/md/PathwaysIcon.svg?react';
import { ANALYSIS_STATUS } from '@/consts';
import ListHeader from '../core/ListHeader';
import ListItemLink from '../core/ListItemLink';

const PathwayAnalysesList = ({ pathways }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const succeeded = pathways.filter(
    pathway => pathway.analysis_status === ANALYSIS_STATUS.succeeded && !pathway.analysis_stale,
  );

  if (!succeeded?.length) {
    return null;
  }

  const gotoPathway = (event, pathway) => {
    event.preventDefault();
    navigate(`/projects/${projectId}/build/pathways/${pathway.id}`);
  };

  return (
    <div className="divide-y divide-[#EAEAEA]">
      <ListHeader title="Pathways" icon={PathwaysIcon} />
      {succeeded.length > 0 && (
        <ul className="p-2">
          {succeeded.map(pathway => (
            <li key={pathway.id} className="group/item">
              <ListItemLink to={`/projects/${projectId}/analysis/pathways/${pathway.id}`}>
                <span className="truncate">{pathway.name}</span>
                <div className="group-hover/item:flex absolute right-5 hidden bg-canvas">
                  <MapIcon onClick={event => gotoPathway(event, pathway)} className="h-5 w-5" />
                </div>
              </ListItemLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PathwayAnalysesList.propTypes = {
  pathways: PropTypes.arrayOf(PropTypes.object),
};

export default PathwayAnalysesList;
