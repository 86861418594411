import PageTitle from '@/components/core/PageTitle';
import { Outlet } from 'react-router-dom';

const ProjectRoute = () => (
  <div className="flex flex-col bg-white shadow-lg shadow-slate-200 rounded-t-lg size-11/12 h-full overflow-hidden">
    <PageTitle className="bg-[#475569] text-white" />
    <Outlet />
  </div>
);

export default ProjectRoute;
