import Layout from '@/components/core/Layout';
import useHandleAuthError from '@/hooks/useHandleAuthError';

const ErrorBoundary = () => {
  useHandleAuthError();
  return (
    <Layout>
      <p className="text-center m-5">Oops! Something went wrong.</p>
    </Layout>
  );
};

export default ErrorBoundary;
